module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","es"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-2350659-1"],"gtagConfig":{"anonymize_ip":true,"send_page_view":false},"pluginConfig":{"exclude":["/*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"court-transparency-charges","short_name":"Court Transparency - Charges","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"187b3df727e746e301eb51a17214cdee"},
    },{
      plugin: require('../node_modules/gatsby-source-google-docs/gatsby-browser.js'),
      options: {"plugins":[],"folders":["1JbHh9wcqZl77g2etn-wsaEkBA8wRrKWm"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-69011402-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f21041a865064c639dd97c634bed1332@o13877.ingest.sentry.io/5474016"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
