import React from "react"
import queryString from "query-string"

const getQueryParams = () => {
  const queryParams = typeof window !== 'undefined' ? window.location.search : ''
  if (queryParams) {
    return queryString.parse(queryParams)
  }
}

const defaultState = {
  timeGroup: "month",
  setTimeGroup: () => {},
  charge: "all",
  setCharge: () => {},
}

const VizContext = React.createContext(defaultState)

class VizProvider extends React.Component {
  state = {
    timeGroup: "month",
    charge: "all"
  }

  componentDidMount () {
    const initialCharge = getQueryParams() ? getQueryParams().charge : "all"
    this.setState({charge: initialCharge})
  }

  render () {
    const { children } = this.props
    const { timeGroup, charge } = this.state

    return (
      <VizContext.Provider
        value={{
          timeGroup,
          setTimeGroup: (timeGroup) => this.setState({timeGroup}),
          charge,
          setCharge: (charge) => this.setState({charge})
        }}
      >
        {children}
      </VizContext.Provider>
    )
  }
}

export default VizContext

export { VizProvider }
